// cachebuster: 1
import React, { Component, useState } from "react";
import queryString from "query-string";
import classNames from "classnames";
import validator from "validator";
import moment from "moment";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  Icon,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withMobileDialog,
} from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import NumberFormat from "react-number-format";
import { cpf, cnpj } from "cpf-cnpj-validator";
import owasp from "owasp-password-strength-test";

import withSnackbar from "./hocs/withSnackbar";
import client from "../apollo";
import withAuth from "./hocs/withAuth";
import withTranslator from "./hocs/withTranslator";
import i18n from "../i18n";

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 8,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
});

const owaspTranslations = {
  "The password must be at least 8 characters long.":
    "A senha deve ter pelo menos 8 caracteres de comprimento.",
  "The password must contain at least one lowercase letter.":
    "A senha deve conter pelo menos uma letra minúscula.",
  "The password must contain at least one uppercase letter.":
    "A senha deve conter pelo menos uma letra maiúscula.",
  "The password must contain at least one special character.":
    "A senha deve conter pelo menos um caractere especial.",
};

const logToSlackMutation = loader("../mutations/LogToSlack.graphql");
const createNewContactMutation = loader(
  "../mutations/SignupNewContact.graphql"
);
const customerExistsQuery = loader("../queries/CustomerExists.graphql");

const styles = (theme) => ({});

const PhoneFormat = (props) => {
  const [numberFormat, setNumberFormat] = useState("+55 (##) ####-####");
  const { inputRef, onChange, ...other } = props;

  const mobileNineIndex = 4;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        const { value } = values;
        // NOTE: We use mobileNineIndex - 2 because the value here does not include the leading 55 but it does below
        if (value.length >= 3) {
          if (value[mobileNineIndex - 2] !== "9") {
            setNumberFormat("+55 (##) ####-####");
          } else if (
            value[mobileNineIndex - 2] === "9" &&
            value.length !== 10
          ) {
            setNumberFormat("+55 (##) # ####-####");
          }
        }
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      onBlur={(event) => {
        const phoneValue = event.currentTarget?.value?.replace(/\D+/g, "");
        if (phoneValue.length === 12) {
          setNumberFormat("+55 (##) ####-####");
        }
        if (
          phoneValue.length === 13 &&
          phoneValue[parseInt(mobileNineIndex, 10)] !== "9"
        ) {
          setNumberFormat("+55 (##) ####-####");
        }
      }}
      onFocus={(event) => {
        const phoneValue = event.target?.value?.replace(/\D+/g, "");
        if (
          phoneValue.length <= 12 &&
          phoneValue.length >= mobileNineIndex + 1 &&
          phoneValue[parseInt(mobileNineIndex, 10)] === "9"
        ) {
          setNumberFormat("+55 (##) # ####-####");
        }
      }}
      isNumericString
      allowEmptyFormatting
      format={numberFormat}
      type="tel"
    />
  );
};

const CPFFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      isNumericString
      format="###.###.###-##"
    />
  );
};

const CNPJFormat = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: { ...values, name: props.name },
        });
      }}
      isNumericString
      format="##.###.###/####-##"
    />
  );
};

const formLabelColor = "rgba(0, 0, 0, 0.54)";
const inputLabelTextVariant = "body2";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      hidePassword: true,

      // inputs:
      type: null,
      cpf: null,
      cnpj: null,
      firstName: null,
      lastName: null,
      telefone: null,
      email: null,
      companyName: null,
      nire: null,
      rgRne: null,
      orgaoExpedidor: null,
      dob: null,
      nationality: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  async handleSubmit() {
    const {
      cpf,
      cnpj,
      firstName,
      lastName,
      telefone,
      email,
      companyName,
      nire,
      password,
    } = this.state;
    const { createNewContact, snackbar, oktaAuth, logToSlack } = this.props;

    // TODO: Pass email and password to okta to create oktaUser and pass oktaId to createNewContact
    // TODO: Validate cpf (for personal) or cnpj (for business) to make sure no brCustomer already exists with a matching one

    const customerExistsWithMatchingCPFCNPJ = await client
      .query({
        query: customerExistsQuery,
        variables: { input: { cpf, cnpj } },
        fetchPolicy: "no-cache",
      })
      .then(
        (res) => res?.data?.brCustomerExists || false,
        (err) => {
          console.error(err);
          return false;
        }
      );

    if (customerExistsWithMatchingCPFCNPJ) {
      snackbar.setState({
        snackbarMessage: `Já existe um cliente com o CPF ou CNPJ fornecido. Se você acha que isso é um erro, entre em contato conosco em contato@energea.com`,
        snackbarOpen: true,
        snackbarVariant: "error",
      });
      return;
    }

    let input = {};
    input = {
      cpf,
      cnpj,
      businessName: companyName,
      nire,
      firstName,
      lastName,
      phone: telefone,
      email,
      password,
    };
    this.setState({ loading: true });
    createNewContact({
      variables: { input },
    }).then(
      () => {
        this.setState({ loading: false });
        snackbar.setState({
          snackbarMessage: `Information submitted. We will be in touch shortly!`,
          snackbarOpen: true,
          snackbarVariant: "success",
        });
        oktaAuth
          .signInWithCredentials({
            username: email,
            password,
          })
          .catch((err) => {
            this.setState({
              errors: [err.message],
              loading: false,
            });
            throw new Error("Error logging in.", err);
          })
          .then(async (res) => {
            const { sessionToken } = res;
            oktaAuth.setOriginalUri("/dashboard");
            oktaAuth.signInWithRedirect({
              sessionToken,
            });
          });
      },
      (err) => {
        logToSlack({
          variables: {
            input: {
              title:
                "Error creating new BrContact from register form (energea.com.br)",
              type: "platform-error",
              data: [
                {
                  label: "Error Message",
                  value: JSON.stringify(err?.message || err),
                },
                {
                  label: "Input",
                  value: JSON.stringify(input),
                },
              ],
            },
          },
        });
        snackbar.setState({
          snackbarMessage:
            err.message ||
            `Erro ao criar usuário. Por favor, tente novamente mais tarde ou entre em contato conosco pelo email contato@energea.com`,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
        this.setState({ loading: false });
      }
    );
  }

  handleClose() {
    this.setState({
      type: null,
      cpf: null,
      cnpj: null,
      firstName: null,
      lastName: null,
      telefone: null,
      email: null,
      companyName: null,
      nire: null,
      rgRne: null,
      orgaoExpedidor: null,
      dob: null,
      nationality: null,
      civilStatus: null,
      activeStep: 0,
    });
  }

  handleBack() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 0:
        this.setState({ type: null });
        break;
      case 1:
      default:
        this.setState({ activeStep: activeStep - 1 });
        break;
    }
  }

  handleNext() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 0:
      case 1:
        this.setState({ activeStep: activeStep + 1 });
        break;
      case 2:
        this.handleSubmit();
        break;
      default:
        this.setState({ activeStep: activeStep + 1 });
        break;
    }
  }

  renderStepper() {
    const { activeStep, type } = this.state;
    if (!type) return null;
    switch (activeStep) {
      case 0:
        return this.renderStep0();
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      default:
        return null;
    }
  }

  renderActionButtons() {
    const { i18n } = this.props;
    const { loading } = this.state;
    return (
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ marginTop: "1rem" }}
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              this.handleBack();
            }}
            disabled={loading}
          >
            {i18n.t("back", "Back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleNext();
            }}
            disabled={this.getContinueDisabled() || loading}
          >
            {loading ? (
              <CircularProgress
                style={{ position: "absolute", color: "white" }}
              />
            ) : null}{" "}
            {i18n.t("continue", "Continue")}
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderNIREInput() {
    const { nire } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          NIRE
        </Typography>
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="Digite o NIRE"
            fullWidth
            value={nire || ""}
            onChange={(event) =>
              this.setState({
                nire: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderCompanyNameInput() {
    const { companyName } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Razão Social
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Razão social da empresa"
            fullWidth
            value={companyName || ""}
            onChange={(event) =>
              this.setState({
                companyName: event.target.value,
              })
            }
            error={companyName && !this.validate("companyName", companyName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderCNPJInput() {
    const { cnpj } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          CNPJ
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o CNPJ"
            fullWidth
            value={cnpj || ""}
            onChange={(event) =>
              this.setState({
                cnpj: event.target.value,
              })
            }
            InputProps={{
              inputComponent: CNPJFormat,
            }}
            error={cnpj && !this.validate("cnpj", cnpj)}
          />
        </FormControl>
      </Grid>
    );
  }
  renderDistribuidoraInput() {
    const { i18n } = this.props;
    const { distribuidora } = this.state;
    return (
      <Grid item>
        <FormControl required fullWidth error={distribuidora === "Other"}>
          <Typography
            style={{ color: formLabelColor }}
            gutterBottom
            variant={inputLabelTextVariant}
          >
            {i18n.t("distributor", "Distributor")}
          </Typography>
          <Select
            fullWidth
            variant="outlined"
            id="distribuidora"
            name="distribuidora"
            required
            value={distribuidora || "placeholder"}
            onChange={(event) =>
              this.setState({
                distribuidora: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    {i18n.t("chooseDistributor", "Choose a distributor")} *
                  </Typography>
                );
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key={`distribuidora-selector-light`}
              name="distribuidora-light"
              value="Light"
            >
              Light
            </MenuItem>
            <MenuItem
              key={`distribuidora-selector-cemig`}
              name="distribuidora-cemig"
              value="CEMIG"
            >
              CEMIG
            </MenuItem>
            <MenuItem
              key={`distribuidora-selector-other`}
              name="distribuidora-other"
              value="Other"
            >
              {i18n.t("other", "Other")}
            </MenuItem>
          </Select>
          <FormHelperText>
            {distribuidora === "Other" &&
              i18n.t(
                "weCanOnlyAcceptCustomersFromTheUtilityCompaniesListedAbove",
                "We can only accept customers from the utility companies listed above."
              )}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  }

  renderRgRneInput() {
    const { rgRne } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          RG/RNE
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o RG/RNE"
            fullWidth
            value={rgRne || ""}
            onChange={(event) =>
              this.setState({
                rgRne: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderOrgaoExpedidorInput() {
    const { orgaoExpedidor } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Órgão expedidor
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite o órgão expedidor do documento"
            fullWidth
            value={orgaoExpedidor || ""}
            onChange={(event) =>
              this.setState({
                orgaoExpedidor: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderDOBInput() {
    const { dob } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Data de nascimento
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a data de nascimento"
            fullWidth
            type="date"
            value={dob || ""}
            onChange={(event) =>
              this.setState({
                dob: event.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
            error={dob && !this.validate("dob", dob)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderNationalityInput() {
    const { nationality } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Nacionalidade
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label="Digite a nacionalidade"
            fullWidth
            value={nationality || ""}
            onChange={(event) =>
              this.setState({
                nationality: event.target.value,
              })
            }
          />
        </FormControl>
      </Grid>
    );
  }

  renderCivilStatusInput() {
    const { civilStatus } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          Estado civil
        </Typography>
        <FormControl required fullWidth>
          <Select
            fullWidth
            variant="outlined"
            id="selecione-o-estado-civil"
            name="selecione-o-estado-civil"
            required
            // label="Test"
            // labelWidth={50}
            value={civilStatus || "placeholder"}
            onChange={(event) =>
              this.setState({
                civilStatus: event.target.value,
              })
            }
            renderValue={(val) => {
              if (val === "placeholder")
                return (
                  <Typography style={{ color: formLabelColor }}>
                    Selecione o estado civil *
                  </Typography>
                );
              if (val === `casado`) return <Typography>Casado (a)</Typography>;
              if (val === `solteiro`)
                return <Typography>Solteiro (a)</Typography>;
              if (val === `viúvo`) return <Typography>Viúvo (a)</Typography>;
              if (val === `divorciado`)
                return <Typography>Divorciado (a)</Typography>;
              return <Typography>{val}</Typography>;
            }}
          >
            <MenuItem
              key="civil-status-casado"
              name="civil-status-casado"
              value="casado"
            >
              Casado (a)
            </MenuItem>
            <MenuItem
              key="civil-status-solteiro"
              name="civil-status-solteiro"
              value="solteiro"
            >
              Solteiro (a)
            </MenuItem>
            <MenuItem
              key="civil-status-viúvo"
              name="civil-status-viúvo"
              value="viúvo"
            >
              Viúvo (a)
            </MenuItem>
            <MenuItem
              key="civil-status-divorciado"
              name="civil-status-divorciado"
              value="divorciado"
            >
              Divorciado (a)
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }

  renderCPFInput() {
    const { i18n } = this.props;
    const { cpf } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          CPF
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterTheCPF", "Enter the CPF")}
            fullWidth
            value={cpf || ""}
            onChange={(event) =>
              this.setState({
                cpf: event.target.value,
              })
            }
            InputProps={{
              inputComponent: CPFFormat,
            }}
            error={cpf && !this.validate("cpf", cpf)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderFirstNameInput() {
    const { i18n } = this.props;
    const { firstName } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          {i18n.t("firstName", "First Name")}
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourFirstName", "Enter your first name")}
            fullWidth
            value={firstName || ""}
            onChange={(event) =>
              this.setState({
                firstName: event.target.value,
              })
            }
            error={firstName && !this.validate("firstName", firstName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderLastNameInput() {
    const { i18n } = this.props;
    const { lastName } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          {i18n.t("lastName", "Last Name")}
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourLastName", "Enter your last name")}
            fullWidth
            value={lastName || ""}
            onChange={(event) =>
              this.setState({
                lastName: event.target.value,
              })
            }
            error={lastName && !this.validate("lastName", lastName)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderTelefoneInput() {
    const { i18n } = this.props;
    const { telefone } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          {i18n.t("telephone", "Phone")}
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourTelephone", "Enter your phone number")}
            fullWidth
            value={telefone || ""}
            onChange={(event) =>
              this.setState({
                telefone: event.target.value,
              })
            }
            InputProps={{
              inputComponent: PhoneFormat,
            }}
            error={telefone && !this.validate("telefone", telefone)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderPasswordInput() {
    const { i18n } = this.props;
    const { hidePassword, password } = this.state;
    const passwordErrors =
      password && password.length >= 4 && owasp.test(password).errors;
    let ptPasswordError = null;
    if (passwordErrors?.length > 0) {
      const englishPasswordError = passwordErrors[0];
      ptPasswordError = owaspTranslations[String(englishPasswordError)] || null;
    }

    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          {i18n.t("password", "Password")}
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourPassword", "Enter your password")}
            fullWidth
            onChange={(event) =>
              this.setState({
                password: event.target.value,
              })
            }
            type={hidePassword ? "password" : "text"}
            value={password || ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    style={{ marginRight: "-11px" }}
                    aria-label="toggle password visibility"
                    onClick={() => {
                      this.setState({ hidePassword: !hidePassword });
                    }}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {hidePassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="password"
            error={!!ptPasswordError}
            helperText={ptPasswordError}
          />
        </FormControl>
      </Grid>
    );
  }
  // renderTermsOfService() {
  //   const { readTerms, password, errors } = this.state;
  //   return (
  //     <Collapse
  //       in={!password || password.length <= 5 || !errors || errors.length === 0}
  //     >
  //       <FormControl
  //         style={{ marginLeft: fullScreen ? 0 : ".6rem" }}
  //         margin="dense"
  //         required
  //         fullWidth
  //       >
  //         <FormControlLabel
  //           control={
  //             <Checkbox
  //               required
  //               style={{ marginRight: "4px" }}
  //               checked={readTerms}
  //               onChange={() => {
  //                 this.setState({ readTerms: !readTerms });
  //               }}
  //             />
  //           }
  //           style={{ marginRight: 0 }}
  //           label={
  //             <FormHelperText style={{ width: "100%", lineHeight: "1.4" }}>
  //               Eu li e concordo com os
  //               <a
  //                 // onClick={e => e.preventDefault()}
  //                 className={classes.aTagBtn}
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //                 href={constants.termsOfServiceUrl}
  //               >
  //                 {" "}
  //                 termos de uso
  //               </a>{" "}
  //               e{" "}
  //               <a
  //                 // onClick={e => e.preventDefault()}
  //                 className={classes.aTagBtn}
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //                 href={constants.privacyPolicyUrl}
  //               >
  //                 política de privacidade
  //               </a>
  //               .
  //             </FormHelperText>
  //           }
  //         />
  //       </FormControl>
  //     </Collapse>
  //   );
  // }

  renderEmailInput() {
    const { email } = this.state;
    return (
      <Grid item>
        <Typography
          style={{ color: formLabelColor }}
          gutterBottom
          variant={inputLabelTextVariant}
        >
          E-mail
        </Typography>
        <FormControl required fullWidth>
          <TextField
            variant="outlined"
            required
            label={i18n.t("enterYourEmail", "Enter your email")}
            fullWidth
            value={email || ""}
            onChange={(event) =>
              this.setState({
                email: event.target.value,
              })
            }
            error={email && !this.validate("email", email)}
          />
        </FormControl>
      </Grid>
    );
  }

  renderStep0() {
    const { type } = this.state;
    const { i18n } = this.props;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {i18n.t(
              "enterAccountHoldersInformation",
              "Enter the account holder's information"
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {type === "personal" ? (
              <>
                {this.renderDistribuidoraInput()}{" "}
                {/* NOTE: This isn't saved. Just used to filter out ineligible customers */}
                {this.renderCPFInput()}
              </>
            ) : (
              <>
                {this.renderDistribuidoraInput()}{" "}
                {/* NOTE: This isn't saved. Just used to filter out ineligible customers */}
                {this.renderCNPJInput()}
                {this.renderCompanyNameInput()}
                {this.renderNIREInput()}
              </>
            )}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep1() {
    const { i18n } = this.props;
    const { type } = this.state;
    const title =
      type === "personal"
        ? i18n.t(
            "enterAccountHoldersInformation",
            "Enter the account holder's information"
          )
        : i18n.t(
            "enterPersonalContactInformation",
            "Enter your personal contact information"
          );
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {this.renderFirstNameInput()}
            {this.renderLastNameInput()}
            {this.renderTelefoneInput()}
            {type === "business" ? this.renderCPFInput() : null}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  renderStep2() {
    const { i18n } = this.props;
    const { type } = this.state;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {type === "personal"
              ? i18n.t(
                  "registerByCreatingAnEmailAndPassword",
                  "Register by Creating an Email and Password"
                )
              : i18n.t(
                  "legalRepresentativeInformation",
                  "Legal Representative Information"
                )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {this.renderEmailInput()}
            {this.renderPasswordInput()}
          </Grid>
        </Grid>
        {this.renderActionButtons()}
      </Grid>
    );
  }

  validate(attr, val) {
    switch (attr) {
      case "firstName":
      case "lastName":
        return val && val.length >= 2;
      case "cpf":
        return cpf.isValid(val);
      case "cnpj":
        return cnpj.isValid(val);
      case "email":
        return validator.isEmail(val);
      case "password":
        return val && owasp.test(val).errors.length === 0;
      case "telefone":
        return validator.isMobilePhone(val, "pt-BR");
      case "dob":
        return moment(val).isValid() && moment(val).isBefore(moment());
      case "companyName":
        return val && val.length >= 2;
      default:
        return true;
    }
  }

  getContinueDisabled() {
    const {
      activeStep,
      type,
      cpf,
      cnpj,
      firstName,
      lastName,
      telefone,
      email,
      password,
      companyName,
      distribuidora,
    } = this.state;
    switch (activeStep) {
      case 0: {
        if (distribuidora === "Other") return true;
        if (type === "personal") {
          return cpf === null || !this.validate("cpf", cpf);
        }
        if (type === "business") {
          return (
            cnpj === null ||
            !this.validate("cnpj", cnpj) ||
            companyName === null ||
            !this.validate("companyName", companyName)
          );
        }
        return true;
      }
      case 1:
        if (type === "personal") {
          return (
            firstName === null ||
            !this.validate("firstName", firstName) ||
            lastName === null ||
            !this.validate("lastName", lastName) ||
            telefone === null ||
            !this.validate("telefone", telefone)
          );
        }
        if (type === "business") {
          return (
            firstName === null ||
            !this.validate("firstName", firstName) ||
            lastName === null ||
            !this.validate("lastName", lastName) ||
            telefone === null ||
            !this.validate("telefone", telefone)
          );
        }
        return true;
      case 2:
        return (
          email === null ||
          !this.validate("email", email) ||
          password === null ||
          !this.validate("password", password)
        );
      default:
        return false;
    }
  }

  render() {
    const { fullScreen, theme, i18n } = this.props;
    const { activeStep, type } = this.state;
    const { bblform } = queryString.parse(window.location.search);
    return (
      // <Dialog fullScreen open={true}>
      //   <DialogContent style={{ padding: 0 }}>
      <Grid container style={{ background: "#fff" }}>
        <Grid item container>
          <Hidden smDown>
            <Grid
              style={{
                backgroundSize: "cover",
                // height: "100%",
                position: "relative",
                backgroundImage:
                  "url(https://res.cloudinary.com/dwelloptimal/image/upload/v1654724323/energea/br-images/solar-panel-in-perspective-2022-03-04-01-48-32-utc_1.jpg)",
              }}
              item
              md={4}
              xs={false}
            >
              <Grid
                container
                style={{ marginTop: "2.5rem", width: "100%" }}
                justifyContent="center"
              >
                <Grid item>
                  <Grid
                    container
                    style={{ width: "100%" }}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="space-between"
                  >
                    <Grid item>
                      <img
                        style={{
                          position: "absolute",
                          top: 40,
                          right: -81,
                        }}
                        src="https://res.cloudinary.com/dwelloptimal/image/upload/v1654724967/energea/br-images/selo.png"
                        alt="Selo"
                      />
                      {/* <Link to="/" className="no-select">
                        <img
                          style={{
                            marginRight: "1.5rem",
                            marginTop: "-6px",
                            verticalAlign: "middle",
                          }}
                          width={constants.headerLogoWidth}
                          src={constants.headerLogoUrl}
                          alt="Logo"
                        />
                      </Link> */}
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h1"
                        style={{
                          fontSize: "32px",
                          fontWeight: 400,
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        Onde você quer
                      </Typography>
                      <Typography
                        variant="h1"
                        style={{
                          color: theme.palette.green1.main,
                          fontSize: "48px",
                          lineHeight: "135%",
                        }}
                      >
                        economizar?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid
            item
            md={8}
            xs={12}
            style={{
              padding: "2rem 1rem",
              // height: "100vh",
              position: "relative",
            }}
          >
            <Grid
              container
              justifyContent="center"
              // style={{ overflow: "scroll", minHeight: "600px" }}
            >
              <Grid item md={9} xs={12}>
                {/* <Button
                  style={{
                    color: theme.palette.green2.main,
                    marginBottom: "32px",
                  }}
                  variant="text"
                  onClick={this.handleClose}
                  startIcon={<ArrowBackIos />}
                >
                  Voltar para o site
                </Button> */}
                <Typography
                  gutterBottom
                  color="primary"
                  variant="h2"
                  style={{ marginBottom: "2rem" }}
                >
                  {i18n.t("signUp", "Sign Up")}
                </Typography>
                {!bblform ? (
                  <Grid container>
                    <Collapse in={type === null}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: fullScreen ? "1rem 0" : "2rem 0",
                          }}
                        >
                          <Typography
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                            variant={fullScreen ? "body1" : "h6"}
                          >
                            {i18n.t(
                              "selectTypeOfAccount",
                              "Go ahead, select the type of unit you want to sync."
                            )}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Card
                                elevation={3}
                                style={{
                                  minWidth: fullScreen ? "9rem" : "12rem",
                                  margin: ".5rem",
                                  backgroundColor: "#f8f8f8",
                                }}
                              >
                                <CardActionArea
                                  style={{
                                    padding: fullScreen ? 0 : "1rem 0",
                                  }}
                                  onClick={() =>
                                    this.setState({ type: "personal" })
                                  }
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{ textAlign: "center" }}
                                      spacing={fullScreen ? 1 : 3}
                                    >
                                      <Grid item>
                                        <Icon
                                          className={classNames(
                                            "fa-light",
                                            "fa-house-chimney"
                                          )}
                                          style={{
                                            fontSize: fullScreen
                                              ? "2rem"
                                              : "3rem",
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          height: fullScreen ? "2rem" : "3rem",
                                          minWidth: "5rem",
                                        }}
                                      >
                                        <Typography
                                          color="inherit"
                                          variant="h6"
                                          style={{
                                            fontWeight: "bold",
                                            // textAlign: "center",
                                          }}
                                          // className={classes.typeText}
                                        >
                                          {i18n.t(
                                            "individualProperty",
                                            "Individual Property"
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          height: fullScreen ? "3rem" : "5rem",
                                          minWidth: "5rem",
                                        }}
                                      >
                                        <Typography
                                          color="inherit"
                                          variant="body1"
                                          // style={{ textAlign: "center" }}
                                          // className={classes.typeText}
                                        >
                                          {i18n.t(
                                            "consumerUnitRegisteredForCPFHolder",
                                            "Consumer unit registered for a CPF holder"
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Card
                                style={{
                                  minWidth: fullScreen ? "9rem" : "12rem",
                                  margin: ".5rem",
                                  backgroundColor: "#f8f8f8",
                                }}
                                elevation={3}
                              >
                                <CardActionArea
                                  style={{
                                    padding: fullScreen ? 0 : "1rem 0",
                                  }}
                                  onClick={() =>
                                    this.setState({ type: "business" })
                                  }
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{ textAlign: "center" }}
                                      spacing={fullScreen ? 1 : 3}
                                    >
                                      <Grid item>
                                        <Icon
                                          className={classNames(
                                            "fa-sharp",
                                            "fa-light",
                                            "fa-city"
                                          )}
                                          style={{
                                            fontSize: fullScreen
                                              ? "2rem"
                                              : "3rem",
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          height: fullScreen ? "2rem" : "3rem",
                                          minWidth: "5rem",
                                        }}
                                      >
                                        <Typography
                                          variant="h6"
                                          color="inherit"
                                          // className={classes.typeText}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {i18n.t(
                                            "businessProperty",
                                            "Business Property"
                                          )}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        style={{
                                          height: fullScreen ? "3rem" : "5rem",
                                          minWidth: "5rem",
                                        }}
                                      >
                                        <Typography
                                          color="inherit"
                                          variant="body1"
                                          // className={classes.typeText}
                                        >
                                          {i18n.t(
                                            "consumerUnitRegisteredForCNPJHolder",
                                            "Consumer unit registered for a CNPJ holder"
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Collapse>
                    <Collapse
                      in={type === "personal" || type === "business"}
                      style={{ width: "100%" }}
                    >
                      <Stepper
                        style={{ paddingTop: 0 }}
                        alternativeLabel
                        activeStep={activeStep}
                      >
                        {[1, 2, 3].map((step) => (
                          <Step key={String(step)}>
                            <StepLabel />
                          </Step>
                        ))}
                      </Stepper>
                      <Grid container justifyContent="center">
                        <Grid
                          item
                          container
                          xs={12}
                          lg={10}
                          // spacing={5}
                          justifyContent="center"
                          // alignItems="center"
                        >
                          {this.renderStepper()}
                        </Grid>
                      </Grid>
                    </Collapse>
                    {/* <Collapse
                      in={type === "business"}
                      style={{ width: "100%" }}
                    >
                      <Stepper
                        style={{ paddingTop: 0 }}
                        alternativeLabel
                        activeStep={activeStep}
                      >
                        {[1, 2, 3].map((step) => (
                          <Step key={String(step)}>
                            <StepLabel />
                          </Step>
                        ))}
                      </Stepper>
                      <Grid container justifyContent="center">
                        <Grid
                          item
                          container
                          xs={12}
                          lg={10}
                          // spacing={5}
                          justifyContent="center"
                          // alignItems="center"
                        >
                          {this.renderStepper()}
                        </Grid>
                      </Grid>
                    </Collapse> */}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withAuth(
  withSnackbar(
    graphql(createNewContactMutation, {
      name: "createNewContact",
    })(
      graphql(logToSlackMutation, {
        name: "logToSlack",
      })(
        withStyles(styles, { withTheme: true })(
          withMobileDialog()(withTranslator(Register)),
          {
            whiteBackground: true,
          }
        )
      )
    )
  )
);
