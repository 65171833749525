import React, { useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import { PictureAsPdf } from "@material-ui/icons";
import moment from "moment";

import withTranslator from "./hocs/withTranslator";

const invoiceListQuery = loader("../queries/InvoiceList.graphql");

const InvoiceList = (props) => {
  const { data, i18n, loadingOverride } = props;
  const [invoicesDialogOpen, setInvoicesDialogOpen] = useState(false);
  if (data.loading || loadingOverride) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "300px" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (data.error) {
    return (
      <Alert severity="error" style={{ width: "100%" }}>
        Apologies! There was an error calculating your accounts performance.
        Please try back later.
      </Alert>
    );
  }

  const filteredInvoices =
    data.consumerUnit.recentBrCreditCompensations?.filter(
      (el) => !!el.brInvoice
    );
  if (!filteredInvoices?.[0]) {
    return (
      <Alert severity="info" style={{ width: "100%" }}>
        {i18n.t("noInvoices", "No Invoices")}
      </Alert>
    );
  }
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "300px" }}
      >
        <Grid item>
          <List style={{ width: "100%" }} dense>
            {filteredInvoices.map((creditCompensation) => (
              <ListItem key={`invoice-list-item-${creditCompensation.id}`}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor:
                        creditCompensation.brInvoice.paymentStatus.iconColor,
                    }}
                  >
                    <Icon
                      className={
                        creditCompensation.brInvoice.paymentStatus.iconClass
                      }
                      style={{
                        color: "#fff",
                        width: "auto",
                      }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={moment(
                    creditCompensation.brInvoice.brBillingCycle.billingMonth
                  ).format("MMMM YYYY")}
                  secondary={creditCompensation.brInvoice.paymentStatus.label}
                />
                <ListItemSecondaryAction>
                  {/* <IconButton edge="end" aria-label="view">
              <Visibility />
            </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="download"
                    size="small"
                    color="primary"
                    component="a"
                    download
                    href={
                      creditCompensation.brInvoice.stripeInvoice
                        ?.invoiceDownloadUrl ||
                      creditCompensation.brInvoice.downloadUrl
                    }
                    disabled={
                      !creditCompensation.brInvoice.stripeInvoice
                        ?.invoiceDownloadUrl &&
                      !creditCompensation.brInvoice.downloadUrl
                    }
                  >
                    <PictureAsPdf />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Button
              //   size="small"
              color="primary"
              style={{ float: "right" }}
              onClick={() => setInvoicesDialogOpen(true)}
            >
              {i18n.t("seeAllInvoices", "See all invoices")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={!!invoicesDialogOpen}
        onClose={() => setInvoicesDialogOpen(false)}
      >
        <DialogContent>Under Development</DialogContent>
      </Dialog>
    </>
  );
};

export default graphql(invoiceListQuery)(withTranslator(InvoiceList));
