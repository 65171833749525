import React from "react";
import { Cloudinary } from "@cloudinary/url-gen";
import {
  AdvancedVideo,
  lazyload,
  responsive,
  accessibility,
  placeholder,
} from "@cloudinary/react";
import {
  Card,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  Avatar,
  withStyles,
  IconButton,
  withMobileDialog,
} from "@material-ui/core";
import { trim } from "@cloudinary/url-gen/actions/videoEdit";

import { scale } from "@cloudinary/url-gen/actions/resize";
import { Close, PlayArrow, ArrowBack, ArrowForward } from "@material-ui/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import withTranslator from "./hocs/withTranslator";

const styles = (theme) => ({
  darkBackground: {
    background: theme.palette.primary.dark,
  },
  lightBackground: { background: "#fff" },
});
// Initialize Cloudinary instance
const cld = new Cloudinary({
  cloud: {
    cloudName: "dwellOptimal",
  },
});

const TestimonialSlide = withMobileDialog()(
  ({ testimonial, fullScreen, i18n }) => {
    const [openVideo, setOpenVideo] = React.useState(false);
    const width = fullScreen ? 720 : 1220;
    const handleOpenVideo = () => {
      setOpenVideo(true);
    };

    const handleCloseVideo = () => {
      setOpenVideo(false);
    };

    // Construct image URL using Cloudinary public ID
    const imageUrl = cld
      .video(testimonial.videoPublicId)
      .videoEdit(trim().startOffset(testimonial.imageStartOffset || 0))
      .resize(scale().width(width))
      .format("webp")
      .toURL();

    // Create a CloudinaryVideo object
    const video = cld
      .video(testimonial.videoPublicId)
      .resize(scale().width(1220))
      .quality("auto")
      .format("auto");

    return (
      <Card
        elevation={8}
        style={{
          margin: "1rem",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{
              background: "rgba(255,255,255,.9)",
              padding: fullScreen ? "2rem" : "4rem 2rem",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              {fullScreen && (
                <Grid item xs={12}>
                  <Avatar
                    src={imageUrl}
                    alt={testimonial.businessName}
                    style={{
                      width: fullScreen ? 140 : 80,
                      height: fullScreen ? 140 : 80,
                      margin: "auto",
                    }}
                    imgProps={{ loading: "lazy" }} // Lazy loading the image
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "fallback_image_url";
                    }}
                  />
                </Grid>
              )}
              <Grid item xs>
                <Typography
                  variant="h6"
                  style={{
                    textAlign: fullScreen ? "center" : "left",
                    fontWeight: "bold",
                  }}
                >
                  {testimonial.businessName}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ textAlign: fullScreen ? "center" : "left" }}
                >
                  {testimonial.location}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              style={{
                margin: fullScreen ? "2rem 0 2rem" : "4rem 0 2rem",
                fontStyle: "italic",
                textAlign: "justify",
                fontSize: "1.1rem",
              }}
              paragraph
            >
              "{testimonial.quote}"
            </Typography>

            <Typography
              variant="body2"
              style={{ textAlign: "right" }}
              paragraph
            >
              - {testimonial.quoteAuthor}, {testimonial.quoteTitle}
            </Typography>
            {fullScreen && (
              <Grid container justifyContent="center">
                <Grid item style={{ marginTop: "1rem" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    startIcon={<PlayArrow />}
                    onClick={handleOpenVideo}
                    style={{
                      borderRadius: "50px",
                      borderWidth: "3px",
                      margin: "auto",
                    }}
                  >
                    {i18n.t("watchTestimonial", "Watch Testimonial")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!fullScreen && (
            <Grid
              xs={12}
              md={6}
              lg={8}
              style={{
                backgroundImage: `linear-gradient(to right, white 0%, rgba(255,255,255,0.9) 0%, transparent 00%), url(${imageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  startIcon={<PlayArrow />}
                  onClick={handleOpenVideo}
                  style={{
                    marginTop: 24,
                    borderRadius: "50px",
                    background: "rgba(255,255,255,.95)",
                    borderWidth: "3px",
                  }}
                >
                  {i18n.t("watchTestimonial", "Watch Testimonial")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Dialog
          open={openVideo}
          onClose={handleCloseVideo}
          maxWidth="md"
          fullScreen={fullScreen}
          aria-labelledby="video-dialog-title"
        >
          {" "}
          <DialogContent style={{ padding: 0, background: "#000" }}>
            <IconButton
              style={{ position: "absolute", right: 0, zIndex: 1400 }}
              onClick={handleCloseVideo}
            >
              <Close
                style={{
                  color: "#fff",
                  width: "2rem",
                  height: "2rem",
                  filter: "drop-shadow( 0 0 2px rgba(0,0,0,0.6))",
                  "-webkit-filter": "drop-shadow( 0 0 2px rgba(0,0,0,0.6))",
                }}
              />
            </IconButton>
            <Grid container style={{ height: "100%" }} alignItems="center">
              <Grid key="about-us-video" item xs={12}>
                {openVideo && (
                  <AdvancedVideo
                    style={{
                      width: "100%",
                    }}
                    width="1220px"
                    autoPlay={!fullScreen}
                    autoPlayMode="always"
                    controls
                    // sources={sources}
                    cldVid={video}
                    plugins={[
                      lazyload(),
                      responsive(),
                      accessibility(),
                      placeholder(),
                    ]}
                    preload="auto"
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Card>
    );
  }
);

const CustomForwardArrow = (props) => {
  const { className, style, onClick, theme } = props;
  return (
    <ArrowBack
      color="primary"
      onClick={onClick}
      style={{
        ...style,
        display: "block",
        color: theme.palette.primary.dark,
        "&:hover": { color: theme.palette.primary.light },
      }}
      className={className}
    />
  );
};
const CustomBackArrow = (props) => {
  const { className, style, onClick, theme } = props;
  return (
    <ArrowForward
      color="primary"
      onClick={onClick}
      style={{
        ...style,
        display: "block",
        color: theme.palette.primary.dark,
        "&:hover": { color: theme.palette.primary.light },
      }}
      className={className}
    />
  );
};

const CustomerTestimonials = (props) => {
  const { classes, i18n, fullScreen, theme } = props;
  const cloudName = "dwellOptimal";

  // Testimonials data with Cloudinary public IDs
  const testimonials = [
    {
      imageStartOffset: 6,
      businessName: "Ed Viriato Corrêa Condomínio",
      quoteAuthor: "Ricardo",
      quoteTitle: "Conselho Fiscal do Condomínio",
      quote:
        "Somos clientes da Energea há cinco meses e estamos muito satisfeitos com o desconto que estamos recebendo.",
      videoPublicId:
        "energea/energea-br-customers/Video_-_Condominums_Portuguese",
      location: "Botafogo, Rio de Janeiro",
    },
    {
      imageStartOffset: 27.5,
      businessName: "Nós da Dança",
      quoteAuthor: "Regina",
      quoteTitle: "Proprietário / Instrutor",
      quote:
        "Gostamos muito dessa parceria com a Energea, porque ao mesmo tempo estamos ajudando o planeta com energia limpa e também recebendo um desconto na nossa conta de luz, o que nos ajuda muito.",
      videoPublicId:
        "energea/energea-br-customers/Video_-_Ballet_v3_Portuguese",
      location: "Copacabana, Rio de Janeiro",
    },
    {
      imageStartOffset: 12,
      businessName: "Marinha Atlântico",
      quoteAuthor: "Victoria",
      quoteTitle: "Gerente",
      quote:
        "Nossos custos com energia são de aproximadamente R$ 20.000 por mês, e com a energia solar, podemos reduzir cerca de 15% do total. Foi excepcional!",
      videoPublicId:
        "energea/energea-br-customers/Video_-_Raf_s_Restaurant_v2_Portuguese",
      location: "Ipanema, Rio de Janeiro",
    },
    // {
    //   imageStartOffset: 26,
    //   businessName: "Casas Pedro",
    //   quoteAuthor: "Luis",
    //   quoteTitle: "Gerente",
    //   quote:
    //     "An indispensable tool for our team	. An indispensable tool for our team. An indispensable tool for our team.",
    //   videoPublicId: "energea/energea-br-customers/Video_-_Casas_Pedro_por",
    //   location: "Itaguaí, Rio de Janeiro",
    // },
    // {
    //   imageStartOffset: 27,
    //   businessName: "Casa Shopping",
    //   quoteAuthor: "Luis",
    //   quoteTitle: "Gerente",
    //   quote:
    //     "An indispensable tool for our team	. An indispensable tool for our team. An indispensable tool for our team.",
    //   videoPublicId: "energea/energea-br-customers/Video_-_Casa_Shopping_por",
    //   location: "Itaguaí, Rio de Janeiro",
    // },
  ];

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !fullScreen,
    prevArrow: <CustomForwardArrow theme={theme} />,
    nextArrow: <CustomBackArrow theme={theme} />,
  };
  const darkTheme = false;
  return (
    <>
      <Grid
        container
        style={{
          width: "100%",
          position: "relative",
        }}
        className={darkTheme ? classes.darkBackground : classes.lightBackground}
        justifyContent="center"
      >
        <div
          id="customer-testimonials"
          style={{ position: "absolute", top: "-80px" }}
        ></div>
        <Grid item style={{ width: "100%" }} xl={8} lg={9} xs={12}>
          <Grid
            item
            md
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",
              padding: "1rem",
            }}
          >
            <Typography
              color="primary"
              style={{
                color: darkTheme && "#fff",
                marginBottom: "1.5rem",
              }}
              variant="h2"
            >
              {i18n.t("customerTestimonials", "Customer Testimonials")}
            </Typography>
            <Typography
              variant={fullScreen ? "h6" : "h5"}
              style={{ color: darkTheme && "#fff", margin: "3rem 0" }}
              paragraph
            >
              <b style={{ color: theme.palette.secondary.main }}>
                Junte-se a mais de 1.000 clientes que já estão economizando com
                a Energea.
              </b>{" "}
              Ao contrário da maioria dos concorrentes, entregamos nossa energia
              de forma rápida, para que você comece a economizar o quanto antes.
              Não acredite apenas na nossa palavra—ouça o que nossos clientes
              têm a dizer.
            </Typography>
            <div container style={{ margin: fullScreen ? "2rem 0" : "2rem" }}>
              <Slider {...sliderSettings}>
                {testimonials.map((testimonial, index) => (
                  <TestimonialSlide
                    key={index}
                    testimonial={testimonial}
                    cloudName={cloudName}
                    i18n={i18n}
                  />
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withMobileDialog()(
  withStyles(styles, { withTheme: true })(withTranslator(CustomerTestimonials))
);
