import React from "react";
import { useOktaAuth } from "@okta/okta-react";

const withAuth = (WrappedComponent) => (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  if (!authState) return null;
  return (
    <WrappedComponent
      {...props}
      accessToken={authState?.accessToken?.accessToken}
      authState={authState}
      oktaAuth={oktaAuth}
    />
  );
};
export default withAuth;
