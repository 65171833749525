import React from "react";
import { graphql } from "react-apollo";

import { loader } from "graphql.macro";
import { useOktaAuth } from "@okta/okta-react";

const requiredAuthQuery = loader("../../queries/RequiredAuth.graphql");
// test
const withRequiredAuth = (WrappedComponent) => {
  const HOC = (props) => {
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;
    if (
      isAuthenticated &&
      props?.data &&
      !props.data.loading &&
      !props.data?.me?.brContact
    ) {
      // oktaAuth.signOut({
      //   postLogoutRedirectUri: `${window.location.origin}/login`,
      // });
    }
    return <WrappedComponent {...props} />;
  };
  return graphql(requiredAuthQuery)(HOC);
};

export default withRequiredAuth;
