import React, { Component } from "react";

import { Grid, withMobileDialog } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { withStyles } from "@material-ui/core/styles";
import withTranslator from "./hocs/withTranslator";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = () => ({});
const logos = [
  `${process.env.PUBLIC_URL}/partner-logos/smart-fit.png`,
  `${process.env.PUBLIC_URL}/partner-logos/casas-pedro.png`,
  // `${process.env.PUBLIC_URL}/partner-logos/drogarias-pacheco.png`,
  `${process.env.PUBLIC_URL}/partner-logos/casa-shopping.png`,
  "https://res.cloudinary.com/dwelloptimal/image/upload/v1653066256/energea/br-images/vivo-logo-825312px.png",
];
class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormOpen: false, // eslint-disable-line
    };
  }

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    const { fullScreen } = this.props;
    const { index } = this.state;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        // style={{ maxHeight: "12rem" }}
      >
        {fullScreen ? (
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
          >
            {logos.map((logo, i) => (
              <Grid
                key={`client-logo-mobile-${i}`}
                container
                alignItems="center"
                style={{ height: "100%", width: "100%", padding: "4rem" }}
              >
                <Grid item style={{ margin: "2rem" }}>
                  <img
                    alt="Energea logo"
                    width="100%"
                    src={logo}
                    style={{ padding: i === 4 ? "0 1rem .8rem" : null }}
                  />
                </Grid>
              </Grid>
            ))}
          </AutoPlaySwipeableViews>
        ) : (
          logos.map((logo, i) => (
            <Grid
              key={`client-logo-${i}`}
              style={{ padding: "3rem" }}
              item
              lg={2}
              md={5}
              sm={7}
              xs={9}
            >
              <img
                alt="Energea logo"
                width="100%"
                src={logo}
                style={{ padding: i === 4 ? "0 1rem .8rem" : null }}
              />
            </Grid>
          ))
        )}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(withTranslator(Clients))
);
