import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  Grid,
  CircularProgress,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
// import { Chart } from "chart.js";
import "chart.js/auto";
import "chartjs-adapter-moment";

import { Alert } from "@material-ui/lab";
import { loader } from "graphql.macro";
import { graphql } from "react-apollo";
import moment from "moment";
import numeral from "numeral";
import { formatCurrency } from "../lib/formatting";
import withTranslator from "./hocs/withTranslator";

const clear = "rgba(255,255,255,0)";

const getGradient = (context, color) => {
  const { chart } = context;
  const { ctx, chartArea } = chart;

  if (!chartArea) {
    return null;
  }
  const gradient = ctx.createLinearGradient(
    0,
    chartArea.top,
    0,
    chartArea.bottom
  );
  gradient.addColorStop(0, color);
  // gradient.addColorStop(0.5, "rgba(255,255,255,.1)");
  gradient.addColorStop(1, clear);
  return gradient;
};

const performanceGraphQuery = loader("../queries/PerformanceGraph.graphql");
const getChartData = (data, theme, kpi) => {
  const formattedLabels = data.map((el) =>
    moment(el.brBillingCycle?.billingMonth).toDate()
  );
  let savingsCounter = 0;
  let co2counter = 0;
  const chartData = {
    labels: formattedLabels,
    datasets: [
      kpi === "financial"
        ? {
            label: "Savings",

            data: data.map((el) => {
              savingsCounter += el.savingsAmount;
              return savingsCounter;
            }),
            // backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.light,
            fill: true,
            lineTension: 0.5,
            // pointRadius: 0,
            backgroundColor: (context) =>
              getGradient(context, theme.palette.secondary.main),
            //   ? datasetBackgroundColors['Dividends Paid']
            //   : getGradient,
            borderWidth: 4,
          }
        : {
            label: "Metric Tons of CO2 Avoided",
            data: data.map((el) => {
              co2counter += el.injectedElectricity;
              return co2counter * 0.699;
            }),
            // backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.main,
            fill: true,
            lineTension: 0.5,
            // pointRadius: 0,
            backgroundColor: (context) =>
              getGradient(context, theme.palette.primary.light),
            //   ? datasetBackgroundColors['Dividends Paid']
            //   : getGradient,
            borderWidth: 4,
          },
    ],
  };
  return chartData;
};

const PerformanceGraph = (props) => {
  const theme = useTheme(); // Access the theme object here
  const [selectedPerformance, setSelectedPerformance] = useState("financial");
  const { data, i18n } = props;
  if (data.loading) {
    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "300px" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  if (data.error) {
    return (
      <Alert severity="error" style={{ width: "100%" }}>
        {i18n.t(
          "errorCalculatingPerformance",
          "Apologies! There was an error calculating your accounts performance. Please try back later."
        )}
      </Alert>
    );
  }
  if (!data.consumerUnit?.brCreditCompensations?.[0]) {
    return (
      <Alert severity="info" style={{ width: "100%" }}>
        {i18n.t("noAccountActivity", "No Account Activity")}
      </Alert>
    );
  }

  const financialChartData = getChartData(
    data.consumerUnit.brCreditCompensations,
    theme,
    "financial"
  );
  const environmentalChartData = getChartData(
    data.consumerUnit.brCreditCompensations,
    theme,
    "environmental"
  );
  const chartData =
    selectedPerformance === "financial"
      ? financialChartData
      : environmentalChartData;

  const chartOptions = {
    maintainAspectRatio: false,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        position: "nearest",
        callbacks: {
          label: (tooltipItem) =>
            selectedPerformance === "financial"
              ? `${tooltipItem.dataset.label}: ${formatCurrency(
                  tooltipItem.formattedValue
                )}`
              : `${tooltipItem.dataset.label}: ${numeral(
                  tooltipItem.formattedValue
                ).format("0,0")} Tons`,
          // footer: (tooltipItem) => {
          //   const total = tooltipItem.reduce(function (sum, cur) {
          //     return sum + cur.raw;
          //   }, 0);
          //   return `Gross CAFD: ${formatCurrency(total)}`;
          // },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          tooltipFormat: "MMM YYYY",
          displayFormats: {
            day: "MMM 'YY",
          },
        },
        // ticks: {
        //   // color: "white",
        //   // callback: (value, index, values) => {
        //   //   if (index === 0) {
        //   //     return value;
        //   //   }
        //   //   if (index === values.length - 1) {
        //   //     return value
        //   //   }
        //   //   return null;
        //   // },
        // },
        grid: {
          display: false,
        },
      },
      y: {
        // stacked: true,
        // position: "right",
        beginAtZero: true,
        ticks: {
          callback: (value) =>
            selectedPerformance === "financial"
              ? `R$${numeral(value).format("0,0")}`
              : `${numeral(value).format("0,0")} Tons`,
          // maxTicksLimit: 6,
        },
      },
    },
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-around"
          style={{ margin: ".5rem 0" }}
        >
          <Grid
            item
            component={Card}
            elevation={selectedPerformance === "financial" ? 0 : 3}
            style={{
              minWidth: "180px",
              margin: ".5rem 0",
              backgroundColor:
                selectedPerformance === "financial"
                  ? theme.palette.primary.light
                  : undefined,
            }}
            onClick={() => setSelectedPerformance("financial")}
          >
            <CardActionArea>
              <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                style={{ padding: "1.5rem 1rem" }}
              >
                <Grid item>
                  <i
                    style={{
                      fontSize: "2rem",
                      padding: ".5rem 0 1rem",
                      color:
                        selectedPerformance === "financial"
                          ? "#fff"
                          : undefined,
                    }}
                    class="fa-duotone fa-money-bill-trend-up"
                  ></i>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    style={{
                      color:
                        selectedPerformance === "financial"
                          ? "#fff"
                          : undefined,
                      fontWeight: "bold",
                    }}
                  >
                    {`${"R"}${numeral(
                      financialChartData.datasets[0].data[
                        financialChartData.datasets[0].data.length - 1
                      ]
                    ).format("$0,0")}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        selectedPerformance === "financial"
                          ? "#fff"
                          : undefined,
                    }}
                  >
                    {i18n.t("savingsToDate", "Savings To Date")}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>
          <Grid
            item
            component={Card}
            elevation={selectedPerformance === "environmental" ? 0 : 3}
            onClick={() => setSelectedPerformance("environmental")}
            style={{
              minWidth: "180px",
              margin: ".5rem 0",

              backgroundColor:
                selectedPerformance === "environmental"
                  ? theme.palette.primary.light
                  : undefined,
            }}
          >
            <CardActionArea>
              <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                style={{ padding: "1.5rem 1rem" }}
              >
                <Grid item>
                  <i
                    style={{
                      fontSize: "2rem",
                      padding: ".5rem 0 1rem 0",
                      color:
                        selectedPerformance === "environmental"
                          ? "#fff"
                          : undefined,
                    }}
                    class="fa-duotone fa-seedling"
                  ></i>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      color:
                        selectedPerformance === "environmental"
                          ? "#fff"
                          : undefined,
                    }}
                  >
                    {`${numeral(
                      environmentalChartData.datasets[0].data[
                        environmentalChartData.datasets[0].data.length - 1
                      ]
                    ).format("0,0.0")} t`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        selectedPerformance === "environmental"
                          ? "#fff"
                          : undefined,
                    }}
                  >
                    {i18n.t("CO2Avoided", "CO2 Avoided")}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Line height={300} data={chartData} options={chartOptions} />
      </Grid>
    </Grid>
  );
};

export default graphql(performanceGraphQuery)(withTranslator(PerformanceGraph));
